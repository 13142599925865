.loader{
    width: 100%;
    height: 100%;
    margin-top: 10px;
    position: relative;
    text-align: center;
    border-radius: 100%;
    left: -2.5px;
  }
  
.loader span{
    display: inline-block;
    width: 5px;
    height: 20px;
    margin-left: 2px;
    background-color:red;
  }
 
.loader span:nth-child(1){
    animation: grow 1s ease-in-out infinite;
  }
  
.loader span:nth-child(2){
    animation: grow 1s ease-in-out 0.15s infinite;
  }
  
.loader span:nth-child(3){
    animation: grow 1s ease-in-out 0.30s infinite;
  }
  
.loader span:nth-child(4){
    animation: grow 1s ease-in-out 0.45s infinite;
  }
  
@keyframes grow{
  0%, 100%{
    -webkit-transform: scaleY(1);
    -ms-transform: scaleY(1);
    -o-transform: scaleY(1);
    transform: scaleY(1);
  }

  50%{
    -webkit-transform: scaleY(1.8);
    -ms-transform: scaleY(1.8);
    -o-transform: scaleY(1.8);
    transform: scaleY(1.8);
  }
}
